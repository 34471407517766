import React from "react";

class price_box extends React.Component {
  render() {
    ////console.log("grandtotal",this.props.grand_total)
    return (
      <>
        <div className="grid grid-flow-row space-y-2">
          <div className="flex justify-between">
            <label>Room(s) Total and Cl Fee:</label>
            <input
              className="form-input  text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
              type="text"
              placeholder="Room Cost"
              readOnly
              value={
                this.props.grand_total !== undefined
                  ? `$${parseFloat(this.props.grand_total.total_wo_tax).toFixed(
                      2
                    )}`
                  : ""
              }
            />
          </div>
          <div className="flex justify-between">
            <label>Tax & fees:</label>
            <input
              className="form-input  text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
              type="text"
              placeholder="Tax"
              readOnly
              value={
                this.props.grand_total !== undefined
                  ? `$${parseFloat(this.props.grand_total.tax_total).toFixed(
                      2
                    )}`
                  : ""
              }
            />
          </div>
          <div className="flex justify-between">
            <label>Total :</label>
            <input
              className="form-input  text-black  focus:border-blue-500 rounded  border border-gray-400 shadow-inner"
              type="text"
              placeholder="Total"
              readOnly
              value={
                this.props.grand_total !== undefined
                  ? `$${parseFloat(this.props.grand_total.total_w_tax).toFixed(
                      2
                    )}`
                  : ""
              }
            />
          </div>
        </div>
      </>
    );
  }
}
export default price_box;
